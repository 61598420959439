import React from "react";
import { Form } from "react-bootstrap";
import { PaymentChannel } from "../components/helper";
import { isMobile } from "react-device-detect";

type PaymentChannelsWidgetProperties = {
    transId: string;
    onlyChannelId?: number[];
    paymentChannelId?: number;
    onPaymentChannelUpdate: Function;
    showCash?: boolean;
    channels: PaymentChannel[];
    showAll: boolean;
};

export class PaymentChannelsWidget extends React.Component<PaymentChannelsWidgetProperties> {
    channels = this.props.channels;
    state = {
        paymentChannelId: this.props.paymentChannelId || 0,
        channels: [] as PaymentChannel[],
    };

    async componentDidMount() {
        try {
            let channels = this.channels;
            if (!isMobile) {
                // hilangkan shopeepay jika bukan dibuka dari mobile
                channels = channels.filter((c) => c.id !== 3);
            }
            // sementara opsi shopeepay dihilangkan jika device adalah android
            // if (isAndroid) {
            //     channels = channels.filter((c) => c.id !== 3);
            // }
            // kalau ada
            if (this.props.onlyChannelId) {
                channels = channels.filter((c) =>
                    this.props.onlyChannelId?.includes(c.id)
                );
            }
            if (!this.props.showCash) {
                channels = channels.filter((c) => c.id !== 6);
            }
            this.setState({
                channels: channels,
            });
            // kalau cuma ada 1, set langsung saja
            if (channels.length === 1) {
                this.setPaymentChannel(channels[0].id);
            }
        } catch (exc) {}
    }

    setPaymentChannel = (id: number) => {
        if (this.props.onPaymentChannelUpdate) {
            this.props.onPaymentChannelUpdate(id);
        }
        this.setState({
            paymentChannelId: id,
        });
    };

    renderChannelOptions(
        title: string,
        channels: any[],
        withHR: boolean,
        landscapeIcon: boolean
    ) {
        if (channels.length === 0) {
            return <></>;
        } else {
            return (
                <div>
                    <div hidden={title === ""} className="mb-1">
                        <b>{title}</b>
                    </div>
                    {channels.map((channel: PaymentChannel) => {
                        return (
                            <div key={channel.id}>
                                <Form.Check
                                    type="radio"
                                    checked={
                                        this.state.paymentChannelId ===
                                        channel.id
                                    }
                                    id={
                                        "rad" +
                                        this.props.transId +
                                        channel.id!.toString()
                                    }
                                    onChange={() =>
                                        this.setPaymentChannel(channel.id)
                                    }
                                    name="group-payment-channel"
                                    className="py-1"
                                    label={
                                        <div>
                                            <div className="d-flex align-items-center">
                                                {channel.logo_url && (
                                                    <div className="flex-shrink-1">
                                                        <img
                                                            src={
                                                                channel.logo_url
                                                            }
                                                            width={
                                                                landscapeIcon
                                                                    ? 48
                                                                    : 20
                                                            }
                                                            className="mr-1"
                                                            alt="logo"
                                                        />
                                                    </div>
                                                )}
                                                <div>
                                                    <b>{channel.description}</b>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                ></Form.Check>
                            </div>
                        );
                    })}
                    <hr hidden={!withHR} />
                </div>
            );
        }
    }

    renderChannels() {
        var bank = this.state.channels.filter((o) => o.is_bank_transfer);
        var ewallet = this.state.channels.filter(
            (o) => !o.is_bank_transfer && o.id !== 6 && o.name !== "Credit Card"
        );
        var creditCard = this.state.channels.filter(
            (o) => o.name === "Credit Card"
        );
        var cash = this.state.channels.filter((o) => o.id === 6);
        return (
            <div>
                {this.renderChannelOptions(
                    "Pembayaran di tempat",
                    cash,
                    true,
                    false
                )}
                {this.renderChannelOptions("E-Wallet", ewallet, true, false)}
                {this.renderChannelOptions("Bank Transfer", bank, true, true)}
                {this.renderChannelOptions(
                    "Credit Card",
                    creditCard,
                    false,
                    false
                )}
            </div>
        );
    }

    render() {
        if (this.channels.length === 0) {
            return (
                <div className="text-center">Loading payment channels...</div>
            );
        } else {
            return (
                <div>
                    <div hidden={!this.props.showAll}>
                        {this.renderChannels()}
                    </div>
                </div>
            );
        }
    }
}
