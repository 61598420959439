import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
    canShowCopyToClipboard,
    copyToClipboard,
    PaymentChannel,
} from "../components/helper";
import BCAHowToWidget from "./bankHowTo/BCAHowToWidget";
import axios from "axios";
import DefaultHowToWidget from "./bankHowTo/DefaultHowToWidget";
import { FormattedMessage } from "react-intl";

type Props = {
    paymentChannelId: number;
    vaNumber: string;
    children?: any;
    channels: PaymentChannel[];
};

const formatVaNumber = (vaNumber: string) => {
    return (
        vaNumber.substring(0, 3) +
        " " +
        vaNumber.substring(3, 6) +
        " " +
        vaNumber.substring(6, 9) +
        " " +
        vaNumber.substring(9, 12) +
        " " +
        vaNumber.substring(12)
    );
};

export default function BankInfoWidget(props: Props) {
    // const [currentVAKey, setCurrentVAKey] = useState(0);
    const bank = props.channels.find((o) => o.id === props.paymentChannelId);
    const [howTo, setHowTo] = useState<any>();

    useEffect(() => {
        // ambil data howto
        if (bank?.howto_url) {
            axios
                .get(bank.howto_url)
                .then((res) => {
                    setHowTo(res.data);
                })
                .catch((err) => {});
        } else {
            setHowTo(undefined);
        }
    }, [bank]);

    const renderBankName = () => {
        if (props.paymentChannelId === 7 || props.paymentChannelId === 5) {
            return (
                <div>
                    Bank BCA
                    <img
                        src="https://gmschurch-assets-2.b-cdn.net/payment/bca-logo-96-trans.webp"
                        width={64}
                        className="float-right"
                        style={{ marginTop: "2px" }}
                        alt="BCA"
                    />
                    <hr />
                </div>
            );
        } else {
            return (
                <div>
                    {bank?.name}
                    <hr />
                </div>
            );
        }
    };

    const renderHowTo = () => {
        // khusus VA BCA, tetap pakai cara lama
        if (props.paymentChannelId === 7 || props.paymentChannelId === 5) {
            return <BCAHowToWidget vaNumber={props.vaNumber} />;
        } else if (howTo) {
            return (
                <DefaultHowToWidget howTo={howTo} vaNumber={props.vaNumber} />
            );
        } else {
            return <FontAwesomeIcon icon={faSpinner} spin={true} />;
        }
    };

    return (
        <>
            {renderBankName()}
            <small>No. Rekening:</small>
            <br />
            {!props.vaNumber && (
                <FontAwesomeIcon icon={faSpinner} spin={true} />
            )}
            {props.vaNumber && (
                <>
                    <span className="float-left text-bold va-number">
                        {formatVaNumber(props.vaNumber)}
                    </span>
                    {canShowCopyToClipboard() && (
                        <span
                            className="float-right"
                            onClick={() => {
                                copyToClipboard(props.vaNumber);
                            }}
                        >
                            SALIN
                        </span>
                    )}
                </>
            )}
            <div className="clearfix"></div>
            {props.children !== undefined && (
                <>
                    <hr />
                    {props.children}
                </>
            )}
            <hr />
            {renderHowTo()}
            <div>
                <FormattedMessage
                    id="paymentCompleteInstruction"
                    defaultMessage={
                        'Silahkan klik "Kembali ke Halaman Registrasi" setelah Anda melakukan transaksi'
                    }
                />
            </div>
            <hr />
        </>
    );
}
